// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

// Imports => Controller
import AcFaqController from './ac-faq.controller.js';

// Imports => Components
import AcHero from '@components/ac-hero/ac-hero.web.js';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcSection from '@atoms/ac-section/ac-section.web.js';
import AcHeading from '@atoms/ac-heading/ac-heading.web.js';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web.js';

import AcExpandable from '@molecules/ac-expandable/ac-expandable.web.js';

import coverImage from '@assets/images/shutterstock-46688014.webp';

@inject('store')
@observer
class AcFaq extends AcFaqController {
  render() {
    const { impersonated } = this.store.auth;
    const { preferred_account } = this.store.auth.user;
    const { all } = this.store.faq;

    const allFaq = all;

    const image = {
      responsive: {
        xl: coverImage,
      },
    };

    return (
      <div
        className={
          !impersonated
            ? this.getPageClassNames()
            : this.getImpersonateClassNames()
        }
        key={preferred_account}
      >
        <AcHero
          image={image}
          hasOverlay={false}
          backButton={false}
          style={{ height: '441px' }}
        />
        <AcSection theme={'white'} className={'h-margin-bottom-25'}>
          <AcContainer fluid={false}>
            <AcRow>
              <AcColumn>
                <AcHeading rank={4}>Veelgestelde vragen</AcHeading>
              </AcColumn>
            </AcRow>

            <AcRow>
              {allFaq &&
                allFaq.map(faq => (
                  <AcExpandable key={faq.id} title={faq.question}>
                    <AcRichContent content={faq.answer} />
                  </AcExpandable>
                ))}
            </AcRow>
          </AcContainer>
          <AcRow className={'more-questions'}>
            Voor verdere vragen kunt u contact opnemen met REALIANCE op +31
            (0)20 21 03 180 of{' '}
            <a className="email" href="mailto:invest@realiance.nl">
              invest@realiance.nl
            </a>
          </AcRow>
        </AcSection>
      </div>
    );
  }
}

export default withNamespaces()(AcFaq);
