const AcFormatCurrency = (
  num,
  decimals = 0,
  locale = 'nl-NL',
  currency = 'EUR'
) => {
  const result = Math.round(num).toLocaleString(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: decimals,
  });

  return result;
};

export default AcFormatCurrency;
