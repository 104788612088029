// Imports => Utitlities
import Capitalize from '@utils/ac-capitalize.js';

const AcFormatErrorMessage = error => {
  let msg = false;
  let code =
    error && error.response && error.response.status
      ? error.response.status
      : false;

  if (!code || code === 500) {
    return 'Er is een onbekende fout opgetreden. Probeer het opnieuw of neem contact op met support@realiance.nl';
  }

  if (error.response && error.response.data && error.response.data.errors) {
    msg = [];

    for (let key in error.response.data.errors) {
      if (error.response.data.errors.hasOwnProperty(key)) {
        msg.push(Capitalize(error.response.data.errors[key][0]));
      }
    }

    msg = msg.join(', ');
  } else if (
    error.response &&
    error.response.data &&
    error.response.data.message
  ) {
    msg = error.response.data.message;
  }

  return msg;
};

export default AcFormatErrorMessage;
