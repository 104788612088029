// Imports => React
import React from 'react';
import clsx from 'clsx';

import AcUUID from '@utils/ac-uuid';

const _CLASSES = {
  PAGE: 'ac-page',
  MAIN: 'ac-account',
  IMPERSONATE: 'ac-page--impersonate-active',
};

class AcAccountController extends React.Component {
  constructor(props) {
    super(props);

    this.store = props.store;
  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    await this.store.auth.whoami();
  };

  getPersonalDetails = () => {
    const { user } = this.store.auth;

    return [
      {
        id: AcUUID(),
        horizontal: true,
        label: 'Initialen',
        value: user.initials || '-',
      },
      {
        id: AcUUID(),
        horizontal: true,
        label: 'Voornaam',
        value: user.firstname || '-',
      },
      {
        id: AcUUID(),
        horizontal: true,
        label: 'Achternaam',
        value: user.lastname || '-',
      },
    ];
  };

  getContactDetails = () => {
    const { user } = this.store.auth;

    return [
      {
        id: AcUUID(),
        horizontal: true,
        label: 'E-mailadres',
        value: user.email || '-',
      },
      {
        id: AcUUID(),
        horizontal: true,
        label: 'Telefoonnummer',
        value: user.phone || '-',
      },
      {
        id: AcUUID(),
        horizontal: true,
        label: 'Mobiele telefoonnummer',
        value: user.mobile_number || '-',
      },
    ];
  };

  getAddressDetails = () => {
    const { user } = this.store.auth;

    return [
      {
        id: AcUUID(),
        horizontal: true,
        label: 'Straatnaam & huisnummer',
        value: (user.address && user.address.street) || '-',
      },
      {
        id: AcUUID(),
        horizontal: true,
        label: 'Postcode',
        value: (user.address && user.address.postal_code) || '-',
      },
      {
        id: AcUUID(),
        horizontal: true,
        label: 'Stad',
        value: (user.address && user.address.city) || '-',
      },
      {
        id: AcUUID(),
        horizontal: true,
        label: 'Land',
        value:
          (user.address && user.address.country && user.address.country.name) ||
          '-',
      },
    ];
  };

  getAccountContactDetails = item => {
    let result = [
      {
        id: AcUUID(),
        horizontal: true,
        label: 'E-mailadres',
        value: item.email || '-',
      },
    ];

    if (item.email2) {
      result = [
        ...result,
        {
          id: AcUUID(),
          horizontal: true,
          label: 'E-mailadres 2',
          value: item.email2 || '-',
        },
      ];
    }

    if (item.email3) {
      result = [
        ...result,
        {
          id: AcUUID(),
          horizontal: true,
          label: 'E-mailadres 3',
          value: item.email3 || '-',
        },
      ];
    }

    result = [
      ...result,
      {
        id: AcUUID(),
        horizontal: true,
        label: 'Telefoonnummer',
        value: item.phone || '-',
      },
      {
        id: AcUUID(),
        horizontal: true,
        label: 'Mobiele telefoonnummer',
        value: item.mobile_number || '-',
      },
    ];

    return result;
  };

  getAccountBusinessDetails = item => {
    let result = [
      {
        id: AcUUID(),
        horizontal: true,
        label: 'Naam',
        value: item.name || '-',
      },
      {
        id: AcUUID(),
        horizontal: true,
        label: 'Type',
        value:
          item.type === 'person'
            ? 'Natuurlijk persoon'
            : item.type === 'corporation'
            ? 'Rechtspersoon'
            : '-',
      },
    ];

    if (item.bank_accounts && item.bank_accounts.length > 0) {
      result = [
        ...result,
        ...item.bank_accounts.map((ba, i) => ({
          id: AcUUID(),
          horizontal: true,
          label: `IBAN ${i + 1}`,
          value: this.formatIban(ba.iban),
        })),
      ];
    }

    result = [
      ...result,
      {
        id: AcUUID(),
        horizontal: true,
        label: 'TAX-ID',
        value: item.tax_id_nr || '-',
      },
    ];

    return result;
  };

  getAccountShippingAddressDetails = item => {
    return [
      {
        id: AcUUID(),
        horizontal: true,
        label: 'Straatnaam & huisnummer',
        value: (item.shipping_address && item.shipping_address.street) || '-',
      },
      {
        id: AcUUID(),
        horizontal: true,
        label: 'Postcode',
        value:
          (item.shipping_address && item.shipping_address.postal_code) || '-',
      },
      {
        id: AcUUID(),
        horizontal: true,
        label: 'Stad',
        value: (item.shipping_address && item.shipping_address.city) || '-',
      },
      {
        id: AcUUID(),
        horizontal: true,
        label: 'Land',
        value:
          (item.shipping_address &&
            item.shipping_address.country &&
            item.shipping_address.country.name) ||
          '-',
      },
    ];
  };

  getAccountBillingAddressDetails = item => {
    return [
      {
        id: AcUUID(),
        horizontal: true,
        label: 'Straatnaam & huisnummer',
        value: (item.billing_address && item.billing_address.street) || '-',
      },
      {
        id: AcUUID(),
        horizontal: true,
        label: 'Postcode',
        value:
          (item.billing_address && item.billing_address.postal_code) || '-',
      },
      {
        id: AcUUID(),
        horizontal: true,
        label: 'Stad',
        value: (item.billing_address && item.billing_address.city) || '-',
      },
      {
        id: AcUUID(),
        horizontal: true,
        label: 'Land',
        value:
          (item.billing_address &&
            item.billing_address.country &&
            item.billing_address.country.name) ||
          '-',
      },
    ];
  };

  formatIban = iban => {
    let result = iban;

    var myRegexp = /\w{4}(.*)\w{2}/g;
    var match = myRegexp.exec(result);
    result = result.replace(match[1], '*********');

    return result;
  };

  requestChanges = item => {
    const subject = 'Portal - Wijzigingsverzoek';
    const body = `Verzoek tot wijzigen gegevens voor account "${item.name}"`;
    window.open(
      `mailto:invest@realiance.nl?subject=${subject}&body=${body}`,
      '_blank'
    );
  };

  getStyleClassNames() {
    return clsx(_CLASSES.MAIN);
  }

  getPageClassNames() {
    return clsx(_CLASSES.PAGE, _CLASSES.MAIN);
  }

  getImpersonateClassNames() {
    return clsx(_CLASSES.IMPERSONATE, this.getStyleClassNames());
  }
}

AcAccountController.propTypes = {};

AcAccountController.defaultProps = {};

export default AcAccountController;
