let timer = null;

const AcScrollTo = ($scroller, $target, offset, duration = 1000) => {
  clearTimeout(timer);

  let start = $scroller.scrollTop,
    to = $target.getBoundingClientRect().top - offset,
    change = to,
    currentTime = 0,
    increment = 20;

  const animateScroll = () => {
    currentTime += increment;
    let val = Math.easeInOutQuad(currentTime, start, change, duration);

    $scroller.scrollTop = val;

    if (currentTime < duration) {
      timer = setTimeout(animateScroll, increment);
    }
  };

  animateScroll();
};

export default AcScrollTo;
