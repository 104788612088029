import { parsePhoneNumberFromString } from 'libphonenumber-js';

const AcFormatPhonenumber = (phonenumber, locale = 'nl') => {
  const result = parsePhoneNumberFromString(phonenumber, locale.toUpperCase());

  return result && result.isValid()
    ? result.formatInternational()
    : phonenumber;
};

export default AcFormatPhonenumber;
