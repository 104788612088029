const AcHasNumericCharacter = value => {
  return new RegExp(/[0-9]/).test(value);
};

const AcHasMixedCharacters = value => {
  return new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value);
};

const AcHasSpecialCharacter = value => {
  return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
};

export const StrengthColour = count => {
  if (count < 2) return 'red';
  if (count < 3) return 'yellow';
  if (count < 4) return 'orange';
  if (count < 5) return 'lightgreen';
  if (count < 6) return 'green';
};

export const AcStrengthIndicator = value => {
  let strengths = 0;
  if (value.length > 4) strengths++;
  if (value.length > 7) strengths++;
  if (AcHasNumericCharacter(value)) strengths++;
  if (AcHasMixedCharacters(value)) strengths++;
  if (AcHasSpecialCharacter(value)) strengths++;
  return strengths;
};

export default AcStrengthIndicator;
