// Imports => React
import React from 'react';
import clsx from 'clsx';

import queryString from 'query-string';

const _CLASSES = {
  MAIN: 'ac-authenticate',
  PAGE: {
    MAIN: 'ac-page',
    FULLHEIGHT: 'ac-page--full-height',
    VALIGNCENTER: 'ac-page--v-align-center',
    BACKGROUND: 'ac-page__background',
    IMPERSONATE: 'ac-page--impersonate-active',
  },
  LOADER: {
    WRP: 'ac-loader--cover',
  },
};

class AcImpersonateController extends React.Component {
  constructor(props) {
    super(props);

    this.store = props.store;
    this.routing = props.routing;

    this.state = {};
  }

  componentDidMount() {
    this.handleImpersonate();
  }

  handleImpersonate = () => {
    const { user_id, signature, expires } = queryString.parse(
      window.location.search
    );
    if (!user_id || !signature || !expires) {
      return;
    }
    this.store.auth.impersonateUser(user_id, signature, expires).then(() => {
      const { replace } = this.props.routing;
      replace('/');
    });
  };

  getLoaderWrpClassNames() {
    return clsx(_CLASSES.LOADER.WRP);
  }

  getBackgroundClassNames() {
    return clsx(_CLASSES.PAGE.BACKGROUND);
  }

  getPageClassNames() {
    return clsx(
      _CLASSES.PAGE.MAIN,
      _CLASSES.PAGE.FULLHEIGHT,
      _CLASSES.PAGE.VALIGNCENTER,
      _CLASSES.PAGE.IMPERSONATE,
      this.getStyleClassNames()
    );
  }

  getStyleClassNames() {
    return clsx(_CLASSES.MAIN);
  }
}

AcImpersonateController.propTypes = {};

AcImpersonateController.defaultProps = {};

export default AcImpersonateController;
