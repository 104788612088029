// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

// Imports => Controller
import AcDocumentsController from './ac-documents.controller.js';

// Imports => Components
import AcHero from '@components/ac-hero/ac-hero.web.js';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcSection from '@atoms/ac-section/ac-section.web.js';
import AcHeading from '@atoms/ac-heading/ac-heading.web.js';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web.js';
import AcDocument from '@atoms/ac-document/ac-document.web';

import AcExpandable from '@molecules/ac-expandable/ac-expandable.web.js';

import coverImage from '@assets/images/hero/realiance-hero-bw-2x.jpg';

@inject('store')
@observer
class AcDocuments extends AcDocumentsController {
  handleDownload = async (url, name, id, type) => {
    await this.store.documents.download(id, name, url, type);
  };

  render() {
    const { impersonated } = this.store.auth;
    const { preferred_account } = this.store.auth.user;
    const { all } = this.store.documents;
    const { t } = this.props;

    const files = {};
    const fileKeys = [];

    let n = 0;
    const collection = all;
    const len = all.length;

    for (n; n < len; n++) {
      const item = collection[n];

      if (!files[item.label]) files[item.label] = [];

      files[item.label].push(item);
    }

    const sortedFiles = Object.keys(files)
      .sort((a, b) => {
        if (a === 'general') return -1;
        else if (b === 'general') return 1;
        else {
          return b - a;
        }
      })
      .reduce((obj, key) => {
        obj[key] = files[key];
        fileKeys.push(key);
        return obj;
      }, {});

    const getFileType = (url) => {
      const type = url.split(/[#?]/)[0].split('.').pop().trim();

      let file_type = false;

      switch (type) {
        case 'pdf':
          file_type = 'application/pdf';
          break;

        case 'xls':
          file_type = 'application/xls';
          break;

        case 'application/doc':
        case 'docx':
          file_type = 'application/docx';
          break;

        case 'powerpoint':
          file_type = 'application/ppt';
          break;

        default:
          file_type = false;
      }

      return file_type;
    };

    const buildDocuments = (documents) => {
      return documents
        .sort((a, b) => {
          if (a.filename) {
            return a.filename < b.filename ? -1 : a.filename > b.filename ? 1 : 0;
          } else {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          }
        })
        .map((doc, index) => (
          <AcDocument
            {...doc}
            label={doc.name}
            type={getFileType(doc.path)}
            mimetype={doc.mimetype}
            url={doc.path}
            size={180}
            key={`ac-user-doc-${doc.id}`}
            callback={this.handleDownload}
          />
        ));
    };

    const image = {
      responsive: {
        xl: coverImage,
      },
    };

    return (
      <div
        className={
          !impersonated ? this.getPageClassNames() : this.getImpersonateClassNames()
        }
        key={preferred_account}
      >
        <AcSection theme={'white'} className={'h-margin-bottom-25'}>
          <AcContainer fluid={false}>
            <AcRow>
              <AcColumn>
                <AcHeading rank={4}>Mijn documenten</AcHeading>
              </AcColumn>
            </AcRow>

            <AcRow>
              {fileKeys &&
                fileKeys.map((key) => {
                  const title = key;
                  return (
                    <AcExpandable key={title} title={t(title)}>
                      <div className={'h-padding-y-20'}>
                        {buildDocuments(sortedFiles[key])}
                      </div>
                    </AcExpandable>
                  );
                })}
            </AcRow>
          </AcContainer>
        </AcSection>
      </div>
    );
  }
}

export default withNamespaces()(AcDocuments);
