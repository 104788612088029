const AcFormatPercentage = (num, options) => {
  const value = isNaN(num) ? 0 : num;

  // const precision = options.precision;
  const unit = options.unit || options.unit === false ? options.unit : '%';

  let result2 = ((value / 100) * 100).toFixed(1);
  let result = result2.replace(',','.');

  if (unit) {
    result += unit;
  }

  return result;
};

export default AcFormatPercentage;
