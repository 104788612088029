import Classes from '@utils/ac-classes.js';

Math.easeInOutQuad = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

export class AcAsideNavigation {
  constructor($target, $container, $scroller, anchors, activeClass) {
    this.$target = $target;
    this.$container = $container;
    this.$scroller = $scroller ? $scroller : window;

    this.anchors = anchors;
    this.activeClass = activeClass;
    this.activeAnchor = null;

    this.offset = 80;
    this.duration = 1200;
    this.timer = null;

    this.Classes = new Classes();

    this.addEvents = this.addEvents.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
    this.moveSpy = this.moveSpy.bind(this);
    this.scrollSpy = this.scrollSpy.bind(this);
    this.calculatePosition = this.calculatePosition.bind(this);
    this.setStyles = this.setStyles.bind(this);

    this.addEvents();
  }

  unload() {
    if (this.$scroller)
      this.$scroller.removeEventListener('scroll', this.handleEvent, false);


    let x = 0;
    let len = this.anchors.length;

    for (x; x < len; x++) {
      let anchor = this.anchors[x];
      if (anchor.reference)
        anchor.reference.removeEventListener(
          'click',
          event => {
            this.scrollTo(event, anchor, this.duration);
          },
          false
        );
    }

    if (document) {
      document.removeEventListener('keyup', this.handleKeyUp, false);
    }
  }

  addEvents() {
    if (this.$scroller)
      this.$scroller.addEventListener('scroll', this.handleEvent, false);

    let x = 0;
    let len = this.anchors.length;

    for (x; x < len; x++) {
      let anchor = this.anchors[x];

      anchor.reference.addEventListener(
        'click',
        event => {
          event.preventDefault();
          this.scrollTo(event, anchor, this.duration);
        },
        false
      );
    }

    if (document) {
      document.addEventListener('keyup', this.handleKeyUp, false);
    }
  }

  handleKeyUp(event) {
    const key = event.key || event.which;
    const keys = ['p', 80, 'n', 78];

    if (key) {
      if (keys.indexOf(key) > -1) {

        switch (key) {
          case 'p':
          case 80:
            this.moveSpy('previous');
            break;

          case 'n':
          case 78:
            this.moveSpy('next');
            break;

          default:
        }
      }
    }
  }

  handleEvent(event) {
    let scrollClient = this.$scroller.getBoundingClientRect();
    let scrollTop = this.$scroller.scrollTop - this.offset;

    let containerClient = this.$container.getBoundingClientRect();
    let containerTop = this.$container.offsetTop - this.offset;

    this.scrollSpy(
      event,
      scrollClient,
      scrollTop,
      containerClient,
      containerTop
    );

    this.calculatePosition(
      event,
      scrollClient,
      scrollTop,
      containerClient,
      containerTop
    );
  }

  moveSpy(direction) {
    let anchor = false;

    if (this.activeAnchor === null && direction === 'next') {
      anchor = this.anchors[0];

    } else if (this.activeAnchor === null && direction === 'previous') {
      anchor = this.anchors[this.anchors.length - 1];

    } else {

      let x = 0;
      let len = this.anchors.length;

      for (x; x < len; x++) {
        const hit = this.anchors[x];

        if (hit.id !== this.activeAnchor.id) continue;

        let index = direction === 'next' ? 1 : -1;

        if (typeof this.anchors[x + index] !== 'undefined') {
          anchor = this.anchors[x + index];
        }
      }
    }

    if (anchor) {
      this.scrollTo({}, anchor, this.duration / 2);
    }
  }

  scrollTo(event, anchor, duration) {
    clearTimeout(this.timer);

    this.activeAnchor = anchor;

    let rel = `${anchor.rel}-${anchor.id}`;
    let $section = document.getElementById(rel);

    if (!$section) return;

    let start = this.$scroller.scrollTop,
      to = $section.getBoundingClientRect().top - this.offset,
      change = to,
      currentTime = 0,
      increment = 20;

    const animateScroll = () => {
      currentTime += increment;
      let val = Math.easeInOutQuad(currentTime, start, change, duration);

      this.$scroller.scrollTop = val;

      if (currentTime < duration) {
        this.timer = setTimeout(animateScroll, increment);
      } else {
        this.Classes.addClass(anchor.reference, this.activeClass);
      }
    };
    animateScroll();
  }

  scrollSpy(event, scrollClient, scrollTop, containerClient, containerTop) {
    let activeAnchor = false;

    let x = 0;
    let len = this.anchors.length;

    for (x; x < len; x++) {
      let anchor = this.anchors[x];
      let rel = `${anchor.rel}-${anchor.id}`;

      let $section = document.getElementById(rel);

      if (!$section) return;

      let targetClient = $section.getBoundingClientRect();

      let active =
        scrollClient.top >= targetClient.top - this.offset &&
        scrollClient.top < targetClient.bottom - this.offset;

      if (active) {
        activeAnchor = true;
        this.activeAnchor = anchor;
        this.Classes.addClass(anchor.reference, this.activeClass);
      } else {
        this.Classes.removeClass(anchor.reference, this.activeClass);
      }
    }

    if (!activeAnchor) {
      this.activeAnchor = null;
    }
  }

  calculatePosition(
    event,
    scrollClient,
    scrollTop,
    containerClient,
    containerTop
  ) {
    let update = false;

    if (!this.$target) return;

    let targetClient = this.$target.getBoundingClientRect();

    let result = {
      translateY: 0,
    };

    let targetOffset = scrollClient.height / 2 - targetClient.height / 2;

    let position = {
      top: containerClient.top - targetOffset - this.offset,
      bottom:
        containerClient.bottom -
        targetClient.height -
        targetOffset -
        this.offset,
    };

    if (scrollClient.top < position.top) {
      result.translateY = 0;
      update = true;
      this.Classes.removeClass(this.$target, 'ac-aside-navigation--sticky');
    } else if (
      scrollClient.top > position.top &&
      scrollClient.top < position.bottom
    ) {
      result.translateY = scrollTop - containerTop + targetOffset;
      update = true;
      this.Classes.addClass(this.$target, 'ac-aside-navigation--sticky');
    } else if (scrollClient.top >= position.bottom) {
      result.translateY = containerClient.height - targetClient.height;
      update = true;
      this.Classes.removeClass(this.$target, 'ac-aside-navigation--sticky');
    }

    if (update) this.setStyles(result);
  }

  setStyles(styles) {
    window.requestAnimationFrame(() => {
      this.$target.style.transform = `translateZ(0) translateY(${styles.translateY}px)`;
    });
  }
}

export default AcAsideNavigation;
