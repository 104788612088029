// Imports => React
import React from 'react';
import clsx from 'clsx';

const _CLASSES = {
  PAGE: 'ac-page',
  MAIN: 'ac-dashboard',
  IMPERSONATE: 'ac-page--impersonate-active',
  PROJECT_INTRODUCTIONS: 'project-introductions',
  OVERVIEW_PROJECT_UPDATES: 'overview-project-updates',
};

let _timer = null;

class AcDashboardController extends React.Component {
  constructor(props) {
    super(props);

    this.store = props.store;

    this.init = this.init.bind(this);
    this.goToNewsArchive = this.goToNewsArchive.bind(this);
    this.goToInvestmentMemorandam = this.goToInvestmentMemorandam.bind(this);

    this.state = {
      account:
        this.store.auth.currentUser &&
        this.store.auth.currentUser.preferred_account,
      locale: this.store.settings.currentLanguage,
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(nextProps, prevState) {
    if (_timer) clearTimeout(_timer);

    const { preferred_account } = this.store.auth.currentUser;
    const { currentLanguage } = this.store.settings;

    let account_changed = true;
    let locale_changed = true;

    // Refresh project data after the locale/currentLanguage has changed
    const a = preferred_account && preferred_account.id;
    const b = prevState.account && prevState.account.id;
    if (a === b) account_changed = false;

    if (
      (currentLanguage === null && prevState.locale === null) ||
      (currentLanguage !== null &&
        prevState.locale !== null &&
        currentLanguage === prevState.locale)
    )
      locale_changed = false;

    if (!account_changed && !locale_changed) return;

    this.setState(
      {
        account: preferred_account,
        locale: currentLanguage,
      },
      () => {
        _timer = setTimeout(() => {
          this.init();
        }, 1000);
      }
    );
  }

  async init() {
    await this.store.kpis.fetch();
    await this.store.projects.fetchByStatus('new');
    await this.store.news.fetch();
    await this.store.projectIntroductions.fetchAll();
    await this.store.overviewProjectUpdates.fetchAll();
    const { hash } = window.location;
    if (hash !== '') {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to getElementById.
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element)
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 0);
    }
  }

  goToNewsArchive() {
    const { push } = this.props.routing;

    push('/news');
  }

  goToInvestmentMemorandam(externalUrl) {
    if (typeof window !== 'undefined') {
      window.location.href = `${externalUrl}`;
    }
  }

  getStyleClassNames() {
    return clsx(_CLASSES.MAIN);
  }

  getPageClassNames() {
    return clsx(_CLASSES.PAGE, this.getStyleClassNames());
  }

  getImpersonateClassNames() {
    return clsx(_CLASSES.IMPERSONATE, this.getStyleClassNames());
  }

  getProjectIntroductionsClassNames() {
    return clsx(_CLASSES.PROJECT_INTRODUCTIONS);
  }
}

AcDashboardController.propTypes = {};

AcDashboardController.defaultProps = {};

export default AcDashboardController;
