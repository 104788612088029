// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

// Imports => Controller
import AcAccountController from './ac-account.controller';

// Imports => Molecules
import AcExpandable from '@molecules/ac-expandable/ac-expandable.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcSection from '@atoms/ac-section/ac-section.web';

import AcLoader from '@atoms/ac-loader/ac-loader.web';

import AcHeading from '@atoms/ac-heading/ac-heading.web';

import AcAttributes from '@atoms/ac-attributes/ac-attributes.web';
// import AcButton from '@atoms/ac-button/ac-button.web.js';
// import AcRipple from '@atoms/ac-ripple/ac-ripple.web.js';

@inject('store')
@observer
class AcAccount extends AcAccountController {
  render() {
    const { user, loading } = this.store.auth;
    const { impersonated } = this.store.auth;

    return (
      <div
        className={
          !impersonated
            ? this.getPageClassNames()
            : this.getImpersonateClassNames()
        }
      >
        {!loading && user && (
          <AcSection theme={'white'}>
            <AcContainer fluid={false}>
              <AcRow>
                <AcColumn
                  xs={12}
                  sm={{ size: 10, offset: 1 }}
                  md={{ size: 10, offset: 1 }}
                >
                  <AcHeading rank={4}>Mijn gegevens</AcHeading>
                </AcColumn>
              </AcRow>

              {user.accounts &&
                user.accounts.map(item => (
                  <AcRow>
                    <AcColumn
                      xs={12}
                      sm={{ size: 10, offset: 1 }}
                      md={{ size: 10, offset: 1 }}
                      className={'h-text--align-left'}
                    >
                      <AcExpandable
                        title={
                          <>
                            {item.name}
                            <i
                              className={
                                'ac-icon ac-icon--pencil ac-icon--request-changes'
                              }
                              title={'Wijzigingen doorgeven'}
                              onClick={event => {
                                if (event && event.preventDefault)
                                  event.preventDefault();
                                if (event && event.stopPropagation)
                                  event.stopPropagation();
                                this.requestChanges(item);
                              }}
                            />
                          </>
                        }
                      >
                        <AcRow>
                          <AcColumn
                            xs={12}
                            md={6}
                            className={'h-margin-bottom-25'}
                          >
                            <AcHeading
                              rank={6}
                              className={'h-margin-bottom-25'}
                            >
                              Algemene gegevens
                            </AcHeading>
                            <AcAttributes
                              columns={1}
                              attributes={this.getAccountBusinessDetails(item)}
                            />
                          </AcColumn>

                          <AcColumn
                            xs={12}
                            md={6}
                            className={'h-margin-bottom-25'}
                          >
                            <AcHeading
                              rank={6}
                              className={'h-margin-bottom-25'}
                            >
                              Contact gegevens
                            </AcHeading>
                            <AcAttributes
                              columns={1}
                              attributes={this.getAccountContactDetails(item)}
                            />
                          </AcColumn>

                          <AcColumn
                            xs={12}
                            md={6}
                            className={'h-margin-bottom-25'}
                          >
                            <AcHeading
                              rank={6}
                              className={'h-margin-bottom-25'}
                            >
                              Postadres
                            </AcHeading>
                            <AcAttributes
                              columns={1}
                              attributes={this.getAccountBillingAddressDetails(
                                item
                              )}
                            />
                          </AcColumn>

                          <AcColumn
                            xs={12}
                            md={6}
                            className={'h-margin-bottom-25'}
                          >
                            <AcHeading
                              rank={6}
                              className={'h-margin-bottom-25'}
                            >
                              Bezoekadres
                            </AcHeading>
                            <AcAttributes
                              columns={1}
                              attributes={this.getAccountShippingAddressDetails(
                                item
                              )}
                            />
                          </AcColumn>
                        </AcRow>
                      </AcExpandable>
                    </AcColumn>
                  </AcRow>
                ))}
            </AcContainer>
          </AcSection>
        )}

        {loading && (
          <AcSection theme={'white'}>
            <AcLoader theme={'pitch'} cover={true} loading={loading} />
          </AcSection>
        )}
      </div>
    );
  }
}

export default withNamespaces()(AcAccount);
