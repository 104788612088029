// Imports => React
import React, { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';
import queryString from 'query-string';
import { Fade, Slide } from 'react-reveal';
import loadable from '@loadable/component';

// Imports => Controller
import AcDashboardController from './ac-dashboard.controller.js';

// Imports => Molecules
const AcKPIGroup = loadable(() =>
  import('@molecules/ac-kpi-group/ac-kpi-group.web.js')
);
const AcNewsCard = loadable(() =>
  import('@molecules/ac-news-card/ac-news-card.web.js')
);
const AcProjectIntroduction = loadable(() =>
  import('@molecules/ac-project-introduction/ac-project-introduction.web.js')
);
const AcOverviewProjectUpdate = loadable(() =>
  import('@molecules/ac-overview-project-updates/ac-overview-project-update.web.js')
);

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
const AcSection = loadable(() => import('@atoms/ac-section/ac-section.web.js'));
const AcDivider = loadable(() => import('@atoms/ac-divider/ac-divider.web.js'));
const AcHeading = loadable(() => import('@atoms/ac-heading/ac-heading.web.js'));
const AcRichContent = loadable(() =>
  import('@atoms/ac-rich-content/ac-rich-content.web.js')
);
const AcButton = loadable(() => import('@atoms/ac-button/ac-button.web.js'));
const AcRipple = loadable(() => import('@atoms/ac-ripple/ac-ripple.web.js'));
const AcLoader = loadable(() => import('@atoms/ac-loader/ac-loader.web.js'));

@inject('store', 'routing')
@observer
class AcDashboard extends AcDashboardController {
  render() {
    const { impersonated } = this.store.auth;
    const { t } = this.props;
    const { all: KPIs, loading: KPIsLoading } = this.store.kpis;
    const {
      all: ProjectIntroductions,
      loading: ProjectIntroductionsLoading,
    } = this.store.projectIntroductions;
    const {
      current_overview_project_updates: OverviewProjectUpdates,
      loading: OverviewProjectUpdatesLoading,
    } = this.store.overviewProjectUpdates;
    const { loading: ProjectsLoading } = this.store.projects;
    const { all: News, loading: NewsLoading } = this.store.news;
    const { preferred_account } = this.store.auth.currentUser;
    const { currentLanguage } = this.store.settings;

    return (
      <div
        className={
          !impersonated ? this.getPageClassNames() : this.getImpersonateClassNames()
        }
        key={`${preferred_account}-${currentLanguage}`}
      >
        <>
          {!KPIsLoading &&
            !ProjectsLoading &&
            !NewsLoading &&
            !OverviewProjectUpdatesLoading && (
              <>
                <AcSection theme={'white'}>
                  <AcContainer fluid={false}>
                    <AcRow>
                      <AcColumn>
                        <AcHeading rank={4}>Lopende projecten</AcHeading>
                      </AcColumn>
                    </AcRow>

                    {!KPIsLoading && KPIs && KPIs['ongoing'] && (
                      <AcKPIGroup kpis={KPIs['ongoing']} />
                    )}

                    <AcDivider className={'h-margin-y-45'} />

                    <AcRow>
                      <AcColumn>
                        <AcHeading rank={4}>Afgeronde projecten</AcHeading>
                      </AcColumn>
                    </AcRow>

                    {!KPIsLoading && KPIs && KPIs['completed'] && (
                      <AcKPIGroup kpis={KPIs['completed']} />
                    )}
                  </AcContainer>
                </AcSection>

                {ProjectsLoading && (
                  <AcSection theme={'white'}>
                    <AcContainer fluid={false}>
                      <AcRow>
                        <AcColumn className={'h-padding-y-35'}>
                          <Fade>
                            <AcLoader
                              theme={'pitch'}
                              cover={true}
                              loading={ProjectsLoading}
                            />
                          </Fade>
                        </AcColumn>
                      </AcRow>
                    </AcContainer>
                  </AcSection>
                )}

                {!ProjectIntroductionsLoading &&
                  ProjectIntroductions &&
                  ProjectIntroductions.length > 0 && (
                    <AcSection
                      theme={'white'}
                      className={'h-padding-top-70 h-padding-bottom-0'}
                    >
                      <AcContainer fluid={false}>
                        <AcRow>
                          <AcColumn>
                            <AcHeading rank={4} className={'h-margin-bottom-15'}>
                              Projectintroductie
                            </AcHeading>
                          </AcColumn>
                        </AcRow>
                        <AcRow>
                          <AcColumn>
                            <AcHeading
                              rank={8}
                              variant='uppercase'
                              className='is-sub'
                            >
                              U bent uitgenodigd om deel te nemen aan
                            </AcHeading>
                          </AcColumn>
                        </AcRow>

                        <Slide bottom cascade>
                          <AcRow>
                            {ProjectIntroductions &&
                              ProjectIntroductions.map((project, idx) => (
                                <AcColumn
                                  xs={12}
                                  sm={12}
                                  key={`project-${idx}-${project.id}`}
                                  className={'h-display--flex'}
                                >
                                  <AcProjectIntroduction
                                    title={project.title}
                                    image={project.featured_image}
                                    location={project.location}
                                    callback={() =>
                                      this.goToInvestmentMemorandam(
                                        project.issuu_link
                                      )
                                    }
                                  />
                                </AcColumn>
                              ))}
                          </AcRow>
                        </Slide>
                      </AcContainer>
                    </AcSection>
                  )}

                {!OverviewProjectUpdatesLoading &&
                  OverviewProjectUpdates &&
                  OverviewProjectUpdates.length && (
                    <AcSection id={'project-updates'} theme={'white'}>
                      <AcContainer fluid={false}>
                        <AcRow>
                          <AcColumn>
                            <AcHeading rank={4} className={'h-margin-bottom-15'}>
                              Projectupdates
                            </AcHeading>
                          </AcColumn>
                        </AcRow>

                        <AcRow>
                          <AcColumn>
                            <AcHeading
                              rank={8}
                              variant='uppercase'
                              className='is-sub'
                            >
                              De meest recente projectupdates van uw projecten
                            </AcHeading>
                          </AcColumn>
                        </AcRow>

                        <AcRow className={'project-update'}>
                          {OverviewProjectUpdates &&
                            OverviewProjectUpdates.map((projectUpdate, idx) => {
                              return (
                                <AcColumn
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  key={`project-update-${idx}`}
                                >
                                  <AcOverviewProjectUpdate
                                    title={projectUpdate.project.name}
                                    image={projectUpdate.project.featured_image}
                                    featured_images={
                                      projectUpdate.project.project_featured_images
                                    }
                                    location={projectUpdate.project.city}
                                    state={projectUpdate.project.state}
                                    country={projectUpdate.project.country.name}
                                    callback={() =>
                                      this.goToInvestmentMemorandam(
                                        projectUpdate.update_link
                                      )
                                    }
                                  />
                                </AcColumn>
                              );
                            })}
                        </AcRow>
                      </AcContainer>
                    </AcSection>
                  )}
                {/* 
                  <AcSection theme={'white'}>
                    <AcContainer fluid={false}>
                      <AcRow>
                        <AcColumn>
                          <AcHeading rank={4}>Nieuws</AcHeading>
                        </AcColumn>
                      </AcRow>

                      <AcRow>
                        {!NewsLoading &&
                          News &&
                          News.slice(0, 2).map(post => (
                            <AcColumn
                              xs={12}
                              sm={6}
                              key={`news-item-${post.id}`}
                              className={'h-display--flex'}
                            >
                              <Fade>
                                <AcNewsCard
                                  orientation={'vertical'}
                                  id={post.id}
                                  post={post}
                                />
                              </Fade>
                            </AcColumn>
                          ))}

                        {!NewsLoading && (!News || News.length === 0) && (
                          <AcColumn>
                            <Fade>
                              <AcRichContent
                                content={'<p>Geen nieuwsberichten gevonden</p>'}
                                className={'h-text--color-medium h-text--italic'}
                              />
                            </Fade>
                          </AcColumn>
                        )}

                        {NewsLoading && (
                          <AcColumn className={'h-padding-y-35'}>
                            <Fade>
                              <AcLoader
                                theme={'pitch'}
                                cover={true}
                                loading={NewsLoading}
                              />
                            </Fade>
                          </AcColumn>
                        )}
                      </AcRow>

                      {!NewsLoading && News && News.length > 0 && (
                        <AcRow className={'h-padding-top-25'}>
                          <AcColumn>
                            <AcButton
                              theme={'alpha'}
                              callback={this.goToNewsArchive}
                            >
                              {t('Nieuwsarchief')}
                              <AcRipple size={'small'} />
                            </AcButton>
                          </AcColumn>
                        </AcRow>
                      )}
                    </AcContainer>
                  </AcSection>
                */}
              </>
            )}

          {(KPIsLoading || ProjectsLoading || NewsLoading) && (
            <AcSection theme={'white'} fullheight={true}>
              <AcLoader
                theme={'pitch'}
                cover={true}
                loading={NewsLoading || ProjectsLoading || KPIsLoading}
              />
            </AcSection>
          )}
        </>
      </div>
    );
  }
}

export default withNamespaces()(AcDashboard);
