// Imports => React
import React from 'react';
import clsx from 'clsx';

const _CLASSES = {
  PAGE: 'ac-page',
  MAIN: 'ac-faq-article',
  META: 'ac-faq-article__meta',
  INTRODUCTION: 'ac-faq-article__introduction',
  BODY: 'ac-faq-article__body',
  IMPERSONATE: 'ac-page--impersonate-active',
};

let _timer = null;

class AcDocumentsController extends React.Component {
  constructor(props) {
    super(props);

    const { id } = this.props.match.params;

    this.store = props.store;

    this.state = {
      id: id || false,
      account: this.store.auth.currentUser.preferred_account,
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(nextProps, prevState) {
    if (_timer) clearTimeout(_timer);

    const { preferred_account } = this.store.auth.currentUser;
    const { currentLanguage } = this.store.settings;

    let accountHasChanged = true;
    let localeHasChanged = true;

    // Refresh data after the locale/currentLanguage has changed
    const a = preferred_account && preferred_account.id;
    const b = prevState.account && prevState.account.id;
    if (a === b) accountHasChanged = false;

    const hasLocaleSet = currentLanguage !== null && prevState.locale !== null;
    const localeIsTheSame = currentLanguage === prevState.locale;

    if (!hasLocaleSet || (hasLocaleSet && localeIsTheSame))
      localeHasChanged = false;

    if (!accountHasChanged && !localeHasChanged) return;

    this.setState(
      {
        account: preferred_account,
        locale: currentLanguage,
      },
      () => {
        _timer = setTimeout(() => {
          this.init();
        }, 1000);
      }
    );
  }

  init = async () => {
    await this.store.documents.fetchAll();
  };

  getBodyClassNames() {
    return clsx(_CLASSES.BODY);
  }

  getIntroClassNames() {
    return clsx(_CLASSES.INTRODUCTION);
  }

  getMetaClassNames() {
    return clsx(_CLASSES.META);
  }

  getStyleClassNames() {
    return clsx(_CLASSES.MAIN);
  }

  getPageClassNames() {
    return clsx(_CLASSES.PAGE, this.getStyleClassNames());
  }
  getImpersonateClassNames() {
    return clsx(_CLASSES.IMPERSONATE, this.getStyleClassNames());
  }
}

AcDocumentsController.propTypes = {};

AcDocumentsController.defaultProps = {};

export default AcDocumentsController;
