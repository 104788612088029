import FileSaver from 'file-saver';

export const AcDownloadFile = (data, filename, mime) => {
	const blob = new Blob([data], { type: mime || 'application/octet-stream' });

	if (mime.indexOf('pdf')) {
		var fileURL = URL.createObjectURL(blob);
		window.open(fileURL);
	}

	return FileSaver.saveAs(blob, filename);
};

export default AcDownloadFile;
