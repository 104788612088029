// Imports => Moment
import moment from 'moment/min/moment-with-locales';

// Imports => Config
import { getLocale, setLocale } from '@config/moment.js';

const AcFormatDate = (input, from = 'DD-MM-YYYY', to = 'DD MMM Y') => {
  // Set and Get locale settings
  setLocale();
  const locale = getLocale();

  // Verify valid date / moment object
  if (!moment(input, from).isValid()) return input;

  // Format input
  const output = moment(input, from)
    .locale(locale)
    .format(to);

  return output;
};

export default AcFormatDate;
