// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect, withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import ReactCSSTransitionReplace from 'react-css-transition-replace';

// Imports => Assets
/* eslint react-app/import/no-webpack-loader-syntax: off */
// import AcLogoSVG from '-!svg-react-loader!@assets/images/realiance-brand-pitch-2x.svg';
import AcLogoSVG from '-!svg-react-loader!@assets/images/realiance-brand-1x.svg';
import REALIANCEBW from '@assets/images/hero/realiance-hero-bw-2x.jpg';

// Imports => Controllers
import AcAuthenticateController from './ac-authenticate.controller.js';

// Imports => Molecules
import AcAuthSteps from '@molecules/ac-auth-steps/ac-auth-steps.web.js';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcCard from '@atoms/ac-card/ac-card.web.js';

import AcImage from '@atoms/ac-image/ac-image.web.js';
import AcFog from '@atoms/ac-fog/ac-fog.web.js';

import AcLoader from '@atoms/ac-loader/ac-loader.web.js';

@inject('store')
@observer
class AcAuthenticate extends AcAuthenticateController {
  render() {
    const { store, location } = this.props;
    const { mode } = this.state;

    const { from } = location.state || {
      from: { pathname: '/' },
    };

    if (
      store.auth.isAuthorized &&
      location.pathname === window.location.pathname
    ) {
      return <Redirect to={from} />;
    }

    return (
      <div className={this.getPageClassNames()}>
        <AcImage
          source={REALIANCEBW}
          className={this.getBackgroundClassNames()}
          id={'ac-auth-bg'}
        />
        <AcFog />
        <AcContainer>
          <AcRow>
            <AcColumn
              xs={{ size: 12, offset: 0 }}
              sm={{ size: 8, offset: 2 }}
              md={{ size: 8, offset: 2 }}
              lg={{ size: 8, offset: 2 }}
            >
              <AcCard
                theme={'white'}
                alignment={'center'}
                hoverAnimation={false}
              >
                <AcRow noGutters={true} className={'h-padding-top-50'}>
                  <AcColumn xs={12}>
                    <AcLogoSVG className={'ac-auth-logo'} />
                  </AcColumn>
                </AcRow>

                <ReactCSSTransitionReplace
                  transitionName="fade-wait"
                  transitionEnterTimeout={1000}
                  transitionLeaveTimeout={1000}
                  className={'ac-card__content h-margin-top-10'}
                >
                  <AcAuthSteps
                    key={this.forms[mode].id}
                    toasters={store.toasters}
                    {...this.forms[mode].getProps()}
                  />
                </ReactCSSTransitionReplace>

                {store.auth.loading && (
                  <AcLoader
                    theme={'pitch'}
                    cover={true}
                    loading={store.auth.loading}
                  />
                )}
              </AcCard>
            </AcColumn>
          </AcRow>
        </AcContainer>
      </div>
    );
  }
}

export default withNamespaces()(withRouter(AcAuthenticate));
