// Imports => React
import React from 'react';
import clsx from 'clsx';

// Imports => Utils
import AcUUID from '@utils/ac-uuid.js';

const _CLASSES = {
  MAIN: 'ac-authenticate',
  PAGE: {
    MAIN: 'ac-page',
    FULLHEIGHT: 'ac-page--full-height',
    VALIGNCENTER: 'ac-page--v-align-center',
    BACKGROUND: 'ac-page__background',
  },
  LOADER: {
    WRP: 'ac-loader--cover',
  },
};

class AcAuthenticateController extends React.Component {
  constructor(props) {
    super(props);

    this.store = props.store;

    this.state = {
      username: '',
      password: '',
      mode: props.register ? props.register : 'login',
    };

    if (this.state.mode === 'login') {
      this.store.auth.clearAuthentication();
    }

    this.switchMode = this.switchMode.bind(this);

    this.login = this.login.bind(this);

    this.handleLogin = this.handleLogin.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.getLoginFormProps = this.getLoginFormProps.bind(this);
    this.getResetPasswordFormProps = this.getResetPasswordFormProps.bind(this);
    this.getResetPasswordSuccessFormProps = this.getResetPasswordSuccessFormProps.bind(
      this
    );

    this.getUsernameInputOptions = this.getUsernameInputOptions.bind(this);
    this.getPasswordInputOptions = this.getPasswordInputOptions.bind(this);

    this.forms = {
      login: {
        id: AcUUID(),
        mode: 'login',
        getProps: this.getLoginFormProps,
      },
      'forgot-password': {
        id: AcUUID(),
        mode: 'forgot-password',
        getProps: this.getResetPasswordFormProps,
      },
      'forgot-password-success': {
        id: AcUUID(),
        mode: 'forgot-password-success',
        getProps: this.getResetPasswordSuccessFormProps,
      },
    };
  }

  switchMode(mode) {
    if (!mode) return;

    this.setState({ mode, password: '' });
  }

  async login() {
    const { username, password } = this.state;

    if (!username || username === '' || !password || password === '') return;

    await this.store.auth.login({
      username: this.state.username,
      password: this.state.password,
    });
  }

  async forgot() {
    const { username } = this.state;

    if (!username || username === '') return;

    await this.store.auth
      .forgot({
        username: this.state.username,
      })
      .then(() => {
        this.switchMode('forgot-password-success');
      });
  }

  handleLogin(event) {
    if (event) event.preventDefault();

    this.login();
  }

  handleResetPassword(event) {
    if (event) event.preventDefault();

    this.forgot();
  }

  handleInputChange(event, name, value) {
    if (!name) return;
    this.setState({ [name]: value });
  }

  getUsernameInputOptions() {
    return {
      type: 'text',
      value: this.state.username,
      name: 'username',
      label: 'Gebruikersnaam',
      callback: this.handleInputChange,
    };
  }

  getPasswordInputOptions() {
    return {
      type: 'password',
      value: this.state.password,
      name: 'password',
      label: 'Wachtwoord',
      callback: this.handleInputChange,
    };
  }

  getResetPasswordFormProps() {
    return {
      key: this.forms['forgot-password'].id,
      title: 'Wachtwoord vergeten',
      introduction:
        '<p>Controleer uw gebruikersnaam. Wanneer deze juist is zal naar het e-mailadres dat bij REALIANCE bekend is een link worden gestuurd om uw wachtwoord te resetten.</p><p>Mocht u uw gebruikersnaam vergeten zijn kunt u contact opnemen met REALIANCE; <a href="tel:+31 020 210 31 80">020 210 31 80</a> / <a href="mailto:invest@realiance.nl">invest@realiance.nl</a>.</p>',
      fields: [this.getUsernameInputOptions()],
      actions: [
        {
          text: 'Wachtwoord resetten',
          button: {
            theme: 'alpha',
            type: 'submit',
            disabled: !(this.state.username !== ''),
          },
          ripple: {
            theme: 'alpha',
            size: 'small',
          },
        },
        {
          text: 'Terug naar inloggen',
          button: {
            theme: 'alpha',
            size: 'small',
            variant: 'forgot',
            callback: event => {
              if (event) event.preventDefault();
              this.switchMode('login');
            },
          },
        },
      ],
      callback: this.handleResetPassword,
    };
  }

  getLoginFormProps() {
    return {
      key: this.forms['login'].id,
      title: 'Inloggen',
      fields: [this.getUsernameInputOptions(), this.getPasswordInputOptions()],
      actions: [
        {
          text: 'Inloggen',
          button: {
            theme: 'alpha',
            type: 'submit',
            disabled: this.state.username === '' || this.state.password === '',
          },
          ripple: {
            theme: 'alpha',
            size: 'small',
          },
        },
        {
          text: 'Wachtwoord vergeten?',
          button: {
            theme: 'alpha',
            size: 'small',
            variant: 'forgot',
            callback: event => {
              if (event) event.preventDefault();
              this.switchMode('forgot-password');
            },
          },
        },
      ],
      callback: this.handleLogin,
    };
  }

  getResetPasswordSuccessFormProps() {
    return {
      key: this.forms['forgot-password-success'].id,
      title: 'E-mail verzonden',
      introduction: {
        content: `<p>Er is een link naar uw e-mailadres verzonden. Deze link is één uur geldig.</p><p> Door op de link in de e-mail te klikken kunt u een nieuw wachtwoord instellen.</p>`,
      },
      actions: [
        {
          text: 'Terug naar inloggen',
          button: {
            theme: 'alpha',
            size: 'small',
            variant: 'forgot',

            callback: event => {
              if (event) event.preventDefault();
              this.switchMode('login');
            },
          },
        },
      ],
    };
  }

  getLoaderWrpClassNames() {
    return clsx(_CLASSES.LOADER.WRP);
  }

  getBackgroundClassNames() {
    return clsx(_CLASSES.PAGE.BACKGROUND);
  }

  getPageClassNames() {
    return clsx(
      _CLASSES.PAGE.MAIN,
      _CLASSES.PAGE.FULLHEIGHT,
      _CLASSES.PAGE.VALIGNCENTER,
      _CLASSES.PAGE.IMPERSONATE,
      this.getStyleClassNames()
    );
  }

  getStyleClassNames() {
    return clsx(_CLASSES.MAIN);
  }
}

AcAuthenticateController.propTypes = {};

AcAuthenticateController.defaultProps = {};

export default AcAuthenticateController;
