// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

// Imports => Assets
/* eslint react-app/import/no-webpack-loader-syntax: off */
// import AcLogoSVG from '-!svg-react-loader!@assets/images/realiance-brand-pitch-2x.svg';
import AcLogoSVG from '-!svg-react-loader!@assets/images/realiance-brand-1x.svg';
import REALIANCEBW from '@assets/images/hero/realiance-hero-bw-2x.jpg';

// Imports => Controllers
import AcImpersonateController from './ac-impersonate.controller.js';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcCard from '@atoms/ac-card/ac-card.web.js';

import AcImage from '@atoms/ac-image/ac-image.web.js';
import AcFog from '@atoms/ac-fog/ac-fog.web.js';

import AcLoader from '@atoms/ac-loader/ac-loader.web.js';

@inject('routing', 'store')
@observer
class AcImpersonate extends AcImpersonateController {
  render() {
    return (
      <div className={this.getPageClassNames()}>
        <AcImage
          source={REALIANCEBW}
          className={this.getBackgroundClassNames()}
        />
        <AcFog />
        <AcContainer>
          <AcRow>
            <AcColumn
              xs={{ size: 12, offset: 0 }}
              sm={{ size: 8, offset: 2 }}
              md={{ size: 8, offset: 2 }}
              lg={{ size: 8, offset: 2 }}
            >
              <AcCard
                theme={'white'}
                alignment={'center'}
                hoverAnimation={false}
              >
                <AcRow noGutters={true} className={'h-padding-top-50'}>
                  <AcColumn xs={12}>
                    <AcLogoSVG className={'ac-auth-logo'} />
                  </AcColumn>
                </AcRow>
                <AcLoader theme={'pitch'} cover={true} loading={true} />
              </AcCard>
            </AcColumn>
          </AcRow>
        </AcContainer>
      </div>
    );
  }
}

export default withNamespaces()(withRouter(AcImpersonate));
